.personal-services {
    .article--hero {
        @include hero-img(url('/img/personal-services/hero.jpg'));
    }

    .difference-icon {
        margin-bottom: 12px;
        height: 52px;
    }

    .slant-right {
        background: url('/img/backgrounds/dividends-work.svg') no-repeat center center;
        background-size: cover;
    }

    &--callout {
        @include article-callout(url('/img/personal-services/callout.png'));

        // Text in callout
        p {
            margin-top: 4px;
            text-align: center;
            max-width: 550px;
            font-size: 24px;
            line-height: 1.35;
            font-weight: 600;
            color: #ffffff;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.01);
        }
    }

    &--philosophy {
        background-color: #fbf9f4;
        padding: 3rem 1.5rem;

        @include mq(medium) {
            padding: 0;
        }

        &_investment {
            margin-top: 64px;
            margin-bottom: 48px;

            @media (min-width: 1216px) {
                margin-bottom: 0;
                padding: 135px 0;
                background: url('/img/personal-services/investment-style.png') no-repeat right;
                background-size: contain;
                background-size: 50%;
            }

            @media (min-width: 1680px) {
                background-size: 808px;
            }
        }

        &_mutual-fund {
            @media (min-width: 1216px) {
                padding: 65px 0;
                background: url('/img/personal-services/mutual-fund.png') no-repeat left;
                background-size: contain;
                background-size: 50%;
            }

            @media (min-width: 1680px) {
                background-size: 889px;
            }
        }

        &_text {
            h2 {
                margin-bottom: 12px;
                font-size: 24px;
            }

            p {
                max-width: 578px;
            }
        }
    }

    &--portfolio {
        background-color: #fbf9f4;

        p {
            margin: 0 auto 48px;
            max-width: 540px;
            text-align: center;
            font-size: 22px;
            line-height: 1.55;
        }

        img {
            margin: 0 auto;
            width: 978px;
        }
    }
}
