.walsky-difference {
    .article--hero {
        @include hero-img(url('/img/walsky-difference/hero.jpg'));
    }

    &--callout {
        @include article-callout(url('/img/walsky-difference/callout.png'));

        // Text in callout
        p {
            padding: 0 20px;
            margin-top: 4px;
            text-align: center;
            max-width: 550px;
            font-size: 24px;
            line-height: 1.35;
            font-weight: 600;
            color: #ffffff;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.01);
        }
    }

    &--unique {
        margin-top: 0.35em;
        margin-bottom: 0.35em;
        @include mq(small) {
            margin-top: 0.75em;
            margin-bottom: 0.75em;
        }
    }

    &--dividends {
        position: relative;
        bottom: -80px;
        background: url('/img/backgrounds/dividends-work.svg') no-repeat center center;
        background-size: cover;

        > .container {
            h2 {
                padding-top: 128px;
                margin-bottom: 64px;
                font-size: 30px;
                line-height: 1.125;
                @include mq(small) {
                    padding-top: 96px;
                }
            }
            .column {
                margin-bottom: 36px;
                padding-right: 12px;
                @include mq(large) {
                    margin-bottom: 0;
                    padding-right: 70px;
                }
            }
        }

        .difference-icon {
            margin-bottom: 12px;
            height: 52px;
        }

        small {
            margin-top: 64px;
            margin-bottom: 5px;
            display: block;
            text-align: center;
            font-size: 14px;
            font-weight: normal;
        }
    }
}
