.our-company {
    .article--hero {
        @include hero-img(url('/img/our-company/hero.jpg'));
    }

    .difference-icon {
        margin-bottom: 12px;
        height: 52px;
    }

    .slant-right {
        background: url('/img/backgrounds/dividends-work.svg') no-repeat center center;
        background-size: cover;
    }

    &--callout {
        @include article-callout(url('/img/our-company/callout.png'));
        height: 400px;

        // Text in callout
        p {
            margin-top: 8px;
            text-align: center;
            max-width: 550px;
            margin-bottom: 18px;
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.01);

            @include mq(small) {
                font-size: 32px;
            }
        }
    }

    &--members {
        margin-top: 25px;
        margin-bottom: 25px;

        &_img {
            max-width: 378px;
        }

        &_text {
            h2 {
                font-size: 30px;
            }

            h4 {
                margin-top: 18px;
                margin-bottom: 18px;
                font-size: 24px;
                color: #5E6E78;
                font-family: 'Fira Sans', sans-serif;
                font-weight: normal;
            }

            p {
                max-width: 738px;
            }

            div {
                display: flex;

                a {
                    margin-left: 8px;
                    color: #404345;
                    text-decoration: none;
                }
            }
        }
    }

}