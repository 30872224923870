.login {
    &--controls {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @include mq(medium) {
            flex-direction: row;
            align-items: flex-end;
        }
        &_helper {
            margin-top: 8px;
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            text-align: center;
            @include mq(medium) {
                margin-top: 0;
                margin-bottom: 0;
                flex-direction: row;
                justify-content: space-between;
                text-align: left;
            }
        }
    }

    &--content {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
            flex-grow: initial;

            @include mq (medium) {
                flex-grow: 1;
            }
        }

        &_form {
            max-width: 400px;
            padding: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 3px 3px 8px 1px rgba(0,0,0,0.08);

            @include mq (medium) {
                padding: 0;
                box-shadow: none;
            }

            .login-logo {
                margin: 0 auto 2em;
            }

            input {
                max-width: 400px;
            }

            label {
                opacity: 0.8;
                font-family: FiraSans-Medium;
                font-size: 14px;
                color: #404345;
            }

            a {
                font-size: 12px;
            }

            button {
                width: 100%;
                background: #00553B;
                border-radius: 2px;
                font-weight: normal;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 20.54px;
                border: none;
                transition: all 0.3s cubic-bezier(0.49, 0.99, 0.54, 0.98);
                @include mq(medium) {
                    width: 180px;
                    order: 2;
                }
                &:hover,
                &:focus {
                    color: #fff;
                    background: #002f20;
                    text-decoration: none;
                }
            }
        }

        &_text {
            display: none;

            @include mq (medium) {
                display: block;
            }

            h2 {
                margin-top: 18px;
                margin-bottom: 12px;
                font-size: 24px;
                line-height: 1.125;
            }

            li {
                font-size: 18px;
                line-height: 1.5;
                font-weight: 400;
            }

            a {
                display: block;
            }
        }

        &_line {
            display: none;

            @include mq(medium) {
                display: block;
                position: absolute;
                left: 50%;
            }
        }
    }
}