// This file contains very basic styles.
// Set up a decent box model on the root element

html {
    background: #dfe3e8;
    scroll-behavior: smooth;
}

body {
    height: 100%;
    max-width: 1680px;
    margin: 0 auto;
    color: #404345;
    background-color: #fff;
    -webkit-text-size-adjust: none;
    font-weight: 300;
    line-height: 1.33333rem;
}

main {
    background-color: #fff;
}

.main-dont-hide-under-nav {
    padding-top: 115px;
    @media (min-width: 1024px) {
        padding-top: 146px;
    }
}

.has-margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.hidden {
    display: none;
}

.homepage {
    padding-bottom: 1em;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}
// Basic styles for links

a {
    color: $primary-color;
    font-weight: 500;
    text-decoration: underline;
    @include on-event {
        color: #024631;
    }
}

button,
input,
optgroup,
select,
textarea {
    font: normal 125% / 1.4 $text-font-stack;
    font-family: 'Fira Sans', sans-serif;
}

img {
    max-width: 100%;
    display: block;
}

li {
    margin-left: 20px;
    list-style-type: disc;
}

.row-full {
    margin: 0;
}

// Highlighted text is brand color

::selection {
    background: $primary-color;
    color: #333;
}

.article-intro {
    padding: 7em calc(50% - 600px) 0;
    text-align: center;
    @include mq(small) {
        padding: 9em calc(50% - 600px) 0;
    }

    > h1 {
        margin-bottom: 0.5em;
        font-size: 2.15rem;
        font-weight: 400;
        line-height: 1;
        @include mq(small) {
            font-size: 3rem;
        }
    }

    > h3 {
        font-size: 1.45rem;
        font-weight: 300;
        line-height: 1;
        @include mq(small) {
            font-size: 1.75rem;
        }
    }

    > img {
        margin: 0 auto;
    }
}
