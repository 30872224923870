.homepage {
    &--hero {
        height: 400px;
        background: url('/img/homepage/hero.jpg') no-repeat center center;
        background-size: cover;

        @include mq(small) {
            height: 600px;
        }

        @include mq(large) {
            height: 650px;
        }

        @media (min-width: 1550px) {
            height: 700px;
        }

        &_content {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            h1 {
                margin-bottom: 12px;
                font-size: 26px;
                color: #fff;
                line-height: 1.3;

                @include mq(small) {
                    margin-bottom: 18px;
                    max-width: 590px;
                    font-size: 44px;
                }
            }

            p {
                margin-bottom: 24px;
                font-size: 18px;
                color: #fff;
                font-weight: 400;

                @include mq(small) {
                    margin-bottom: 48px;
                    font-size: 22px;
                }
            }
        }
    }

    &--content-block {
        padding-top: 85px;
        padding-bottom: 85px;

        @include mq(small) {
            padding-top: 135px;
            padding-bottom: 155px;
        }
        @include mq(desktop) {
            padding-top: 185px;
            padding-bottom: 125px;
        }

        &:nth-of-type(3) {
            background: url('/img/homepage/slanted-bg-right.svg') no-repeat center center;
            background-size: cover;
        }

        &:nth-of-type(6) {
            background: url('/img/homepage/slanted-bg-left.svg') no-repeat center center;
            background-size: cover;
            margin-bottom: -100px;

            @include mq(large) {
                margin-bottom: -120px;
            }
        }

        &_text {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h2 {
                margin-bottom: 24px;
                font-size: 30px;
                line-height: 1.2;
            }

            p {
                max-width: 390px;
                margin-bottom: 36px;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.5;
            }
        }
    }

    &--newsletter-callout {
        @include article-callout(url('/img/homepage/newsletter-callout.jpg'));

        // Text in callout
        p {
            padding: 0 20px;
            margin-top: 4px;
            text-align: center;
            max-width: 750px;
            font-size: 24px;
            line-height: 1.35;
            font-weight: 600;
            color: #ffffff;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.01);
        }
    }

    &--callout {
        @include article-callout(url('/img/homepage/callout.png'));
        position: relative;
        top: 100px;

        @include mq(large) {
            top: 120px;
        }

        .container {
            position: relative;
            top: -30px;
            text-align: center;
        }

        // Text in callout
        p {
            font-size: 28px;
            font-weight: 700;
            line-height: 1.1;
            color: #ffffff;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);

            @include mq(small) {
                font-size: 28px;
            }
        }

        span {
            font-size: 28px;
            font-weight: 700;
            color: #005239;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

            @include mq(small) {
                font-size: 34px;
            }
        }
    }
}
