.has-background-light {
    background-color: #fbf9f4 !important;
}

// HR Spacers
.hr-content_horizontal {
    margin-top: 0;
    margin-bottom: 25px;
    width: 100px;
    display: inline-block;
    border: 2px solid $primary-color;
}

.hr-content_vertical {
    width: 0;
    position: absolute;
    left: -25px;
    top: 0;
    margin: 0;
    height: 100px;
    border: 2px solid $primary-color;
}

.content-page {
    margin: -90px auto 0;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    &_hero {
        h1 {
            color: #fff;
            font-size: 24px;
            text-transform: capitalize;
            line-height: 1.35;
            font-weight: 600;
            @include mq(small) {
                font-size: 48px;
            }
        }
        p {
            font-size: 24px;
            color: #fff;
            font-style: italic;
            font-weight: 300;
        }
    }
}

.content-page--boxes {
    margin: -366px auto 0;
    padding-top: 20em;
}

.services--synopsis {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    background: #fff;
    @include mq(medium) {
        margin: -5em auto 91px;
        flex-direction: row;
        align-items: inherit;
        background-color: transparent;
    }
}

.spacing--he {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > div {
        flex: 1;
    }
}

.text-block-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.paragraph-block {
    max-width: 640px;
}

.text-block {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 50%;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    min-height: 450px;
    min-width: 300px;
}

.text-block-inside-container {
    max-width: 490px;
    margin: 95px auto 0;
}

.text-block-img-container {
    display: flex;
    align-items: center;
    > img {
        max-width: 225px;
    }
}

// Article Page Layout

.article--hero_content {
    text-align: center;
    color: #fff;
    h2 {
        margin-bottom: 18px;
        font-size: 36px;
        line-height: 1.25;
        text-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.4);
        @include mq(small) {
            font-size: 44px;
        }
    }
    p {
        font-size: 22px;
        font-weight: 400;
    }
}

.article--callout {
    &_text {
        max-width: 600px;
        text-align: center;
        > h3 {
            margin-bottom: 15px;
            color: #fff;
            line-height: 1.5;
            font-size: 1.5rem;
        }
    }
}

.container-slim {
    max-width: 960px;
    p {
        padding: 0 0.75em;
        margin-bottom: 1em;
    }
}

// Grid Helpers

.grid-2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    grid-gap: 25px;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
}

.align-center {
    align-self: center;
}