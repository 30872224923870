@charset 'UTF-8';

// 1. Configuration and helpers
@import 'utilities/variables', 'utilities/functions', 'utilities/media-queries', 'utilities/responsive-embed', 'utilities/mixins';

// 2. Vendors
@import 'vendor/reset';

// 3. Base stuff
@import 'base/base', 'base/spacing', 'base/typography', 'base/helpers';

// 4. Layout-related sections
@import 'layout/main-navigation', 'layout/footer', 'layout/global-layouts';

// 5. Components
@import 'components/buttons', 'components/forms', 'components/ui-patterns';

// 6. Page-specific styles
@import 'pages/homepage', 'pages/walsky-difference', 'pages/our-company', 'pages/contact-us', 'pages/private', 'pages/login', 'pages/dashboard',
    'pages/create-account', 'pages/aux-pages', 'pages/personal-services', 'pages/share';

// 7. Print Styles
@import 'base/print';
