.navbar {
    width: 100%;
    max-width: 1680px;
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: fixed;

    > .container {
        align-items: center;
    }

    &-brand {
        img {
            width: 175px;
            padding: 0.35em 0 0.35em 0.45em;
            @media (min-width: 1024px) {
                width: 225px;
                padding: 0.25em 0;
            }
        }
    }

    .navbar-item {
        padding-top: 20px;
        padding-bottom: 12px;
        color: #0b4a37;
        font-size: 18px;
        font-weight: 400;
        text-decoration: none;
    }

    .navbar-end {
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        &--aux {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #f5f5f4;
            @include mq(medium) {
                flex-direction: row;
                background: none;
            }
        }

        &--main {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include mq(medium) {
                flex-direction: row;
            }
        }
    }

    .nav-hr {
        display: none;
        @media (min-width: 1024px) {
            display: inherit;
            width: 65%;
            height: 1px;
            position: absolute;
            top: 48px;
            right: 0;
            border: 0.5px solid rgba(30, 55, 47, 0.5);
        }
        @media (min-width: 1216px) {
            width: 75%;
        }
    }
}

.navbar-dropdown {
    background: #024631;
}

// Bulma Overrides

.navbar-link.is-active,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:hover {
    background-color: transparent;
    color: $primary-color-dark;
}

.navbar-link:not(.is-arrowless)::after {
    border-color: $primary-color-dark;
}

.navbar-dropdown a.navbar-item:hover {
    background: hsla(180, 32%, 40%, 1);
    text-decoration: none;
}

.navbar-burger {
    top: 30px;
}

.active-nav-item {
    color: $primary-color-dark !important;
}
