.global--footer {
  background-color: #f5f5f4;
  background: url("/img/footer-bg.svg") no-repeat center center;
  background-size: cover;
  &-top {
    padding-top: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @include mq(small) {
      padding-top: 150px;
      margin-bottom: 24px;
      flex-direction: row;
    }
    &_text {
      margin-bottom: 24px;
      @include mq(small) {
        margin-bottom: 0;
      }
      img {
        max-width: 58px;
        margin: 0 auto 12px;
        @include mq(small) {
          margin: 0 0 12px 0;
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        @include mq(small) {
          text-align: left;
        }
      }
    }
    &_signup {
      input {
        background: #ffffff;
        border-radius: 8px;
      }
    }
  }
  &-bottom {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid hsla(204, 4%, 26%, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @include mq(small) {
      flex-direction: row;
    }
    a {
      margin-right: 20px;
      font-size: 12px;
      text-decoration: none;
      color: rgba(47, 142, 113, 0.9);
    }
    small {
      font-size: 12px;
    }
  }
}

.five-percent-logo {
  max-width: 155px;
  margin: 0 auto 0.75em;
}

// Mailchimp overrides

#mc_embed_signup_scroll {
  display: flex;
}

#mc_embed_signup {
  input.email {
    width: 300px !important;
  }
  .button {
    color: #fff !important;
    background: #00553b !important;
    font-family: "Fira Sans", sans-serif !important;
    font-size: 16px !important;
    @include mq(medium) {
      position: relative;
      left: -35px;
    }
  }
}
