.dashboard {
    display: flex;

    &--sidebar {
        display: none;
        width: 150px;
        height: 100vh;
        background: $primary-color;
        @include mq(small) {
            display: block;
        }

        img {
            margin: 25px auto 35px;
        }

        a {
            display: block;
            color: #fff;
            font-size: 15px;
            text-align: center;
        }
    }

    &--main {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;

        nav {
            padding-right: 1.5rem;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            height: 60px;
            background-color: #fbf9f4;
            @include mq(small) {
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
            }
            a {
                display: block;
            }
        }

        &_header {
            > .container {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &--grid {
        display: flex;
        flex-direction: column;
        @include mq(medium) {
            margin-bottom: inherit;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;
        }
    }

    .newsletter-card {
        margin-bottom: 18px;
        @include mq(medium) {
            margin-bottom: inherit;
        }
        h3 {
            color: #404345;
            font-size: 22px;
            font-weight: 700;
            line-height: 1.35;
        }

        h5 {
            color: #404345;
            font-family: 'Fira Sans';
            font-size: 15px;
            line-height: 18px;
            opacity: 0.7;
            font-weight: 400;
        }
        img {
            height: 250px;
            object-fit: cover;
            object-position: center;
        }
    }
}
