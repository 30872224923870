.share-page {
    .article--hero {
        @include hero-img(url('/img/share/hero.jpg'));
    }

    .difference-icon {
        margin-bottom: 12px;
        height: 52px;
    }

    .slant-right {
        background: url('/img/backgrounds/dividends-work.svg') no-repeat center center;
        background-size: cover;
    }

    &--icons {
        margin-top: 24px;

        >div {
            margin-bottom: 24px;
            display: flex;
            align-items: flex-start;

            img {
                margin-right: 18px;
            }
        }
    }

    iframe {
        height: 425px;
    }
}
